import React, { useEffect } from 'react'

import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import Navigation from '../components/navigation'
import SessionACard from '../components/session--card2'
import SessionBCard from '../components/session--card1'
import SessionCCard from '../components/session--card'
import './home.css'

const Home = (props) => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <div className="home-container">
      <Helmet>
        <title>Elyu Sessions</title>
        <meta property="og:title" content="Elyu Sessions" />
      </Helmet>
      <Navigation></Navigation>
      {/* <img alt="image" src="/logo-website-200h.png" className="home-image" /> */}
      <main className="home-main" id="home">
        <div className="home-hero section-container">
          <div className="home-max-width max-content-container">
            <div className="home-heading-container">
              <h1 className="home-text Heading1">
                <span>Capture life memories with</span>
                <br></br>
                <span>Elyu Sessions!</span>
              </h1>
              <span className="home-text04">
                <span>
                  We literally built a &quot;tiny&quot; studio for you to save
                  your life milestones.
                </span>
                <br></br>
                <span>
                  Schedule a session with us, and we&apos;ll prepare the studio
                  for you.
                </span>
                <br></br>
              </span>
              <Link to="/book">
                <button className="home-primary button-primary button-lg button">
                  Book A Session
                </button>
              </Link>
            </div>
            <div className="home-gallery">
              <img
                alt="gallery"
                src="/website-gallery.png"
                className="home-image"
              />
              {/* <div className="home-container1">
                <img
                  alt="image"
                  src="/dscf6477-1000w.jpg"
                  className="home-image1"
                />
              </div>
              <div className="home-container2">
                <img
                  alt="image"
                  src="/dscf6477-1000w.jpg"
                  className="home-image2"
                />
                <img
                  alt="image"
                  src="/dscf6477-1000w.jpg"
                  className="home-image3"
                />
              </div>
              <div className="home-container3">
                <img
                  alt="image"
                  src="/dscf6477-1000w.jpg"
                  className="home-image4"
                />
                <div className="home-container4">
                  <img
                    alt="image"
                    src="/dscf6477-1000w.jpg"
                    className="home-image5"
                  />
                  <img
                    alt="image"
                    src="/dscf6477-1000w.jpg"
                    className="home-image6"
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="home-process section-container">
          <div className="home-max-width1 max-content-container">
            <span className="home-text09">Our process</span>
            <h2 className="home-text10 Heading2">
              <span>
                We use a simple three step process.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>Take a look.</span>
            </h2>
            <div className="home-step">
              <span className="home-text14">01</span>
              <div className="home-container5">
                <span className="home-text15">Book a session</span>
                <span className="home-text16">
                  Proceed to our booking page, and schedule a session with us.
                  You&apos;ll be able to select your preferred time slot,
                  background color and indicate other specifics for your
                  session.
                </span>
              </div>
            </div>
            <div className="home-step1">
              <span className="home-text17">02</span>
              <div className="home-container6">
                <span className="home-text18">Visit the studio</span>
                <span className="home-text19">
                  <span>
                    We are located at
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="home-text21">
                    1 Catalan Road, Brgy. Lingsat, San Fernando City, La Union
                  </span>
                  <span>
                    {' '}
                    2500. It&apos;s actually easy to find! We have a parking
                    area for you if you plan to bring a car.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    Reminders: Please arrive 5 minutes before your session.
                  </span>
                  <br></br>
                </span>
              </div>
            </div>
            <div className="home-step2">
              <span className="home-text27">03</span>
              <div className="home-container7">
                <span className="home-text28">Start your photo session</span>
                <span className="home-text29">
                  Our studio will be prepared for your session according to your
                  booking. We will be accommodating and discussing how your
                  session will go.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-services section-container" id="packages">
          <div className="home-max-width2 max-content-container">
            <div className="home-heading-container1">
              <div className="home-text-container">
                <span className="home-text30">our Packages</span>
                <h2 className="Heading2">Choose your preferred session</h2>
              </div>
              <div className="home-controls"></div>
            </div>
            <div className="home-cards-container">
              <SessionACard></SessionACard>
              <SessionBCard rootClassName="session--card-root-class-name"></SessionBCard>
              <SessionCCard rootClassName="session--card-root-class-name"></SessionCCard>
            </div>
          </div>
        </div>
      </main>
      <div className="section-container">
        <div className="max-content-container">
          <div className="home-top-part">
            <div className="home-links-container">
              <div className="home-product-container">
                <span className="home-text32">Packages</span>
                <span className="home-text33">Session A</span>
                <span className="home-text34">Session B</span>
                <span>Session C</span>
              </div>
              <div className="home-navigate-container">
                <span className="home-text36">Navigate</span>
                <span className="home-text37">Copyrights</span>
                <span className="home-text38">Sitemap</span>
                <span>Privacy Policy</span>
              </div>
              <div className="home-contact-container">
                <span className="home-text40">Our Studio</span>
                <span className="home-text41">
                  <span>Lingsat</span>
                  <br></br>
                  <span>San Fernando City,</span>
                  <br></br>
                  <span>La Union</span>
                  <br></br>
                  <span>2500</span>
                </span>
              </div>
            </div>
            <div className="home-subscribe-container">
              <span className="home-text49">
                Follow our social media pages for more updates!
              </span>
            </div>
          </div>
        </div>
        <div className="home-separator"></div>
        <footer className="home-max-width4 max-content-container">
          <img
            alt="image"
            src="/logo-website-200h.png"
            className="home-image7"
          />
          <span className="home-text50">
            Self-Portrait &quot;Tiny&quot; Studio
          </span>
        </footer>
      </div>
    </div>
  )
}

export default Home
