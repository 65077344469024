import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import './faq.css'

const FAQ = (props) => {
  return (
    <div className="faq-container">
      <Helmet>
        <title>FAQ - Elyu Sessions</title>
        <meta property="og:title" content="FAQ - Elyu Sessions" />
      </Helmet>
      <Navigation></Navigation>
      <img alt="image" src="/logo-website-200h.png" className="faq-image" />
      <main className="faq-main">
        <div className="faq-hero section-container">
          <div className="faq-max-width max-content-container">
            <div className="faq-heading-container">
              <h1 className="faq-text Heading1">Frequently Asked Questions</h1>
              <span className="faq-text001">
                <span>
                  You asked, we answered. Here are the most asked inquiries.
                </span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
        <div className="faq-services section-container">
          <div className="faq-max-width1 max-content-container">
            <div className="faq-heading-container1">
              <div className="faq-text-container">
                <strong className="faq-text004">
                  <span className="faq-text005">
                    Where can I book a session?
                  </span>
                  <br></br>
                  <span>
                    You may reserve your slot thru our website:
                    elyusessions.com/book
                  </span>
                  <br></br>
                  <br></br>
                  <span className="faq-text010">
                    What’s your mode of payment?
                  </span>
                  <br></br>
                  <span>
                    We accept cash and online payments such as
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="faq-text013">GCash</span>
                  <span>
                    ,
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="faq-text015">Maya</span>
                  <span>
                    ,
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="faq-text017">BDO</span>
                  <span>
                    , and
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="faq-text019">BPI</span>
                  <span> bank transfers.</span>
                  <br></br>
                  <br></br>
                  <span className="faq-text023">Do you allow walk-ins?</span>
                  <br></br>
                  <span>
                    We will allow walk-ins depending on the slot availability.
                  </span>
                  <br></br>
                  <br></br>
                  <span className="faq-text028">
                    Do you have a grace period?
                  </span>
                  <br></br>
                  <span>
                    We advise you to arrive 5-10 minutes before your session.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br></br>
                  <br></br>
                  <span className="faq-text033">Can I extend my session?</span>
                  <br></br>
                  <span>
                    Yes, as long as there is no booked session after your slot.
                  </span>
                  <br></br>
                  <span>Minimal fee is required, just inform our staff.</span>
                  <br></br>
                  <br></br>
                  <span className="faq-text040">
                    Can I cancel/reschedule my session?
                  </span>
                  <br></br>
                  <span>
                    - Kindly inform us at least a day before your session if you
                    wish to reschedule or cancel. Check our Booking Policies for
                    more information regarding rescheduling and cancellations.
                  </span>
                  <br></br>
                  <br></br>
                  <span className="faq-text045">
                    Do you provide an official receipt?
                  </span>
                  <br></br>
                  <span>
                    Yes we do, and we will. We are a DTI and BIR registered
                    business.
                  </span>
                  <br></br>
                  <br></br>
                  <span className="faq-text050">
                    Do you offer discounts for students, PWD, and Senior
                    Citizen?
                  </span>
                  <br></br>
                  <span>
                    Absolutely! Discounts will be given, bring your ID for
                    verification.
                  </span>
                  <br></br>
                  <span className="faq-text054">
                    Note that only one customer per session is amenable for the
                    discount
                  </span>
                  <br className="faq-text055"></br>
                  <span className="faq-text056">
                    and his or her name should be the one reserved in the
                    booking.
                  </span>
                  <br></br>
                  <br></br>
                  <span className="faq-text059">
                    Do you offer a birthday discount?
                  </span>
                  <br></br>
                  <span>
                    As our gift, we will give a
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="faq-text062">10%</span>
                  <span>
                    {' '}
                    discount for one session to the birthday celebrant.
                  </span>
                  <br></br>
                  <span>
                    It must be at least 3 days before or after your birthdate.
                  </span>
                  <br></br>
                  <span>Kindly present your ID for verification.</span>
                  <br></br>
                  <br></br>
                  <span className="faq-text070">Can I bring my pet?</span>
                  <br></br>
                  <span>Of course! Pets are always welcome in our studio.</span>
                  <br></br>
                  <br></br>
                  <span className="faq-text075">Can we bring some props?</span>
                  <br></br>
                  <span>
                    You may bring your own props for a more fun photo session.
                  </span>
                  <br></br>
                  <span>Chairs will also be provided and are free to use.</span>
                  <br></br>
                  <span>
                    Kindly refrain from bringing props that will require
                    electricity.
                  </span>
                  <br></br>
                  <br></br>
                  <span className="faq-text084">
                    Do you have a parking area?
                  </span>
                  <br></br>
                  <span>
                    Yes we do have a parking slot in front of our studio.
                  </span>
                  <br></br>
                  <br></br>
                  <span className="faq-text089">
                    Can we shoot our products?
                  </span>
                  <br></br>
                  <span>
                    We are happy to accept product shoots, just message us for
                    the details.
                  </span>
                  <br></br>
                  <br></br>
                  <span className="faq-text094">
                    What camera brand are you using?
                  </span>
                  <br></br>
                  <span>
                    Fujifilm. We’ve been using Fujifilm for more than 10 years
                    now,
                  </span>
                  <br></br>
                  <span>thus allowing us to control the brand easily.</span>
                  <br></br>
                  <br></br>
                  <span className="faq-text101">
                    Can I get my photos in RAW format?
                  </span>
                  <br></br>
                  <span>
                    While we shoot in RAW, we will send the soft copies in JPEG
                    format within 1-3 days.
                  </span>
                </strong>
              </div>
              {/* <div className="faq-controls"></div> */}
            </div>
          </div>
        </div>
      </main>
      <div className="section-container">
        <div className="max-content-container">
          <div className="faq-top-part">
            <div className="faq-links-container">
              <div className="faq-product-container">
                <span className="faq-text104">Packages</span>
                <span className="faq-text105">Session A</span>
                <span className="faq-text106">Session B</span>
                <span>Session C</span>
              </div>
              <div className="faq-navigate-container">
                <span className="faq-text108">Navigate</span>
                <span className="faq-text109">Copyrights</span>
                <span className="faq-text110">Sitemap</span>
                <span>Privacy Policy</span>
              </div>
              <div className="faq-contact-container">
                <span className="faq-text112">Our Studio</span>
                <span className="faq-text113">
                  <span>Lingsat</span>
                  <br></br>
                  <span>San Fernando City,</span>
                  <br></br>
                  <span>La Union</span>
                  <br></br>
                  <span>2500</span>
                </span>
              </div>
            </div>
            <div className="faq-subscribe-container">
              <span className="faq-text121">
                Follow our social media pages for more updates!
              </span>
            </div>
          </div>
        </div>
        <div className="faq-separator"></div>
        <footer className="faq-max-width3 max-content-container">
          <img
            alt="image"
            src="/logo-website-200h.png"
            className="faq-image1"
          />
          <span className="faq-text122">
            Self-Portrait &quot;Tiny&quot; Studio
          </span>
        </footer>
      </div>
    </div>
  )
}

export default FAQ
