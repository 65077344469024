import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import './style.css'
import Home from './views/home'
import Random from './views/random'
import Book from './views/book'
import FAQ from './views/faq'
import PageNotFound from './views/page-not-found'

import PGLU from './views/pglu'
import Selah from './views/events/selah'
import PGLU1114 from './views/events/pglu'

const App = () => {
  return (
    <div>
      <Router>
        <Switch>
          {/* <Route component={Random} exact path="/randomurl" /> */}
          <Route component={Book} exact path="/book" />
          <Route component={FAQ} exact path="/faqs" />
          <Route component={Home} exact path="/" />
          <Route component={PGLU} path="/pictures/0427" />
          <Route component={Selah} path="/pictures/selah" />
          <Route component={PGLU1114} path="/pictures/20241114-PGLU" />
          <Route component={PageNotFound} path="/404" />
          <Route render={() => <Redirect to="/404" />} />
        </Switch>
      </Router>
    </div>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
