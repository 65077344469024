import React from 'react'

import PropTypes from 'prop-types'

import './you-can-book-me.css'

const YouCanBookMe = (props) => {
  return (
    <div className={`you-can-book-me-container ${props.rootClassName} `}></div>
  )
}

YouCanBookMe.defaultProps = {
  rootClassName: '',
}

YouCanBookMe.propTypes = {
  rootClassName: PropTypes.string,
}

export default YouCanBookMe
