import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import YouCanBookMe from '../components/you-can-book-me'
import './book.css'

const Book = (props) => {
  // window.location.replace('https://elyusessions.youcanbook.me/');
  return (
    <div className="book-container">
      <Helmet>
        <title>Book - Elyu Sessions</title>
        <meta property="og:title" content="Book - Elyu Sessions" />
      </Helmet>
      <Navigation></Navigation>
      <img alt="image" src="/logo-website-200h.png" className="book-image" />
      <main className="book-main">
        <div className="book-hero section-container">
          <div className="book-max-width max-content-container">
            {/* <div className="book-heading-container">
              <h1 className="book-text Heading1">
                Redirecting you to booking site...
              </h1>
              <br />
              <br />
              <hr />
              <p>If you have not been redirected within a few seconds, click this button to proceed to the booking site.</p>
              <br />
              <a href="https://elyusessions.youcanbook.me/" data-ycbm-modal="true">
                <button className="book-primary button-primary button-lg button">
                  Book A Session
                </button>
              </a>
            </div> */}
            <div className="book-container1">
              {/* <YouCanBookMe rootClassName="you-can-book-me-root-class-name"></YouCanBookMe> */}
              <iframe
                src="https://elyusessions.youcanbook.me/?noframe=true&skipHeaderFooter=true" id="ycbmiframeelyusessions"
                style={{
                  "width":"100%",
                  "height":"1000px",
                  "border":"0px",
                  "background-color":"transparent"
                }}
                frameborder="0"
                allowtransparency="true">
              </iframe>

                {/* <script>window.addEventListener && window.addEventListener("message", function(event){if (event.origin === "https://elyusessions.youcanbook.me"){document.getElementById("ycbmiframeelyusessions").style.height = event.data + "px";}}, false);</script> */}
            </div>
          </div>
        </div>
      </main>
      {/* <div className="section-container">
        <div className="max-content-container">
          <div className="book-top-part">
            <div className="book-links-container">
              <div className="book-product-container">
                <span className="book-text01">Packages</span>
                <span className="book-text02">Session A</span>
                <span className="book-text03">Session B</span>
                <span>Session C</span>
              </div>
              <div className="book-navigate-container">
                <span className="book-text05">Navigate</span>
                <span className="book-text06">Copyrights</span>
                <span className="book-text07">Sitemap</span>
                <span>Privacy Policy</span>
              </div>
              <div className="book-contact-container">
                <span className="book-text09">Our Studio</span>
                <span className="book-text10">
                  <span>Lingsat</span>
                  <br></br>
                  <span>San Fernando City,</span>
                  <br></br>
                  <span>La Union</span>
                  <br></br>
                  <span>2500</span>
                </span>
              </div>
            </div>
            <div className="book-subscribe-container">
              <span className="book-text18">
                Follow our social media pages for more updates!
              </span>
            </div>
          </div>
        </div>
        <div className="book-separator"></div>
        <footer className="book-max-width2 max-content-container">
          <img
            alt="image"
            src="/logo-website-200h.png"
            className="book-image1"
          />
          <span className="book-text19">
            Self-Portrait &quot;Tiny&quot; Studio
          </span>
        </footer>
      </div> */}
    </div>
  )
}

export default Book
