import React from 'react'

import PropTypes from 'prop-types'

import './session--card.css'

const SessionACard = (props) => {
  return (
    <div className="services-card">
      <div className="session--card-container">
        <img
          alt={props.image_alt}
          src={props.image_src}
          className="session--card-image"
        />
      </div>
      <div className="session--card-container1">
        <span className="session--card-text">{props.text}</span>
        <ol className="list session--card-ul">
          <li className="list-item">
            <span>
              <span>Up to 2 pax</span>
              <br></br>
            </span>
          </li>
          <li className="list-item">
            <span>15 mins photo session</span>
          </li>
          <li className="list-item">
            <span>10 mins photo selection</span>
          </li>
          <li className="list-item">
            <span>1 backdrop color</span>
          </li>
          <li className="list-item">
            <span>2 pcs. postcard prints</span>
          </li>
          <li className="list-item">
            <span>
              <span>All soft copies (JPEG)</span>
              <br></br>
              <br></br>
            </span>
          </li>
        </ol>
      </div>
      <h4 className="session--card-text12">{props.text2}</h4>
    </div>
  )
}

SessionACard.defaultProps = {
  image_alt: 'image',
  image_src: '/website-200h.png',
  text: 'Session A',
  text1:
    'Up to 2 pax - P400 15 mins photoshoot 10 mins photo selection choose 1 backdrop color 2 postcard prints all digital copies in jpeg format',
  text2: 'Php400',
}

SessionACard.propTypes = {
  image_alt: PropTypes.string,
  image_src: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
}

export default SessionACard
