import React from 'react'

import PropTypes from 'prop-types'

import './session--card.css'

const SessionBCard = (props) => {
  return (
    <div className={`services-card ${props.rootClassName} `}>
      <div className="session--card-container">
        <img
          alt={props.image_alt}
          src={props.image_src}
          className="session--card-image"
        />
      </div>
      <div className="session--card-container1">
        <span className="session--card-text">{props.text}</span>
        <ol className="list session--card-ul">
          <li className="list-item">
            <span className="">
              <span className="">Up to 4 pax</span>
              <br className=""></br>
            </span>
          </li>
          <li className="list-item">
            <span className="">20 mins photo session</span>
          </li>
          <li className="list-item">
            <span className="">15 mins photo selection</span>
          </li>
          <li className="list-item">
            <span className="">2 backdrop colors</span>
          </li>
          <li className="list-item">
            <span className="">2 pcs. postcard prints</span>
          </li>
          <li className="list-item">
            <span className="">2 pcs. photo strips</span>
          </li>
          <li className="list-item">
            <span className="">all digital copies (JPEG)</span>
          </li>
        </ol>
      </div>
      <h4 className="session--card-text12">{props.text2}</h4>
    </div>
  )
}

SessionBCard.defaultProps = {
  image_alt: 'image',
  rootClassName: '',
  text: 'Session B',
  text2: 'Php600',
  image_src: '/website-200h.png',
}

SessionBCard.propTypes = {
  image_alt: PropTypes.string,
  rootClassName: PropTypes.string,
  text: PropTypes.string,
  text2: PropTypes.string,
  image_src: PropTypes.string,
}

export default SessionBCard
