import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ScrollToTop from "react-scroll-to-top"

import './navigation-links.css'

const NavigationLinks = (props) => {
  return (
    <nav className={`navigation-links-nav ${props.rootClassName} `}>
      <Link to="/">
        <span
          className="navigation-links-home Navigation-Link"
          onClick={() => {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
          }}
        >
          {props.link1}
        </span>
      </Link>
      <a href="#packages">
        <span className="navigation-links-packages Navigation-Link">
          {props.link2}
        </span>
      </a>
      <Link to="/faqs">
        <span className="navigation-links-packages Navigation-Link">
          {props.link4}
        </span>
      </Link>
      {props.removeBook ? null : (<Link to="/book" className="navigation-links-faq Navigation-Link">
        {props.link31}
      </Link>)}
      <ScrollToTop smooth />
    </nav>
  )
}

NavigationLinks.defaultProps = {
  link3: 'Gallery',
  rootClassName: '',
  link2: 'Packages',
  link4: 'FAQs',
  link1: 'Home',
  link31: 'Book a Session',
}

NavigationLinks.propTypes = {
  link3: PropTypes.string,
  rootClassName: PropTypes.string,
  link2: PropTypes.string,
  link4: PropTypes.string,
  link1: PropTypes.string,
  link31: PropTypes.string,
}

export default NavigationLinks
